import { LOGO_MAIN } from "assets/images";

const HOST = process.env.NEXT_PUBLIC_HOST;
interface FaqDataType {
  questions?: string;
  answer?: string;
}
interface LocationDetailJsonType {
  storeName?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  latitude?: string;
  longitude?: string;
  phone?: string;
  url?: string;
}

export const locationDetailJson = (locationDetailJsonData: LocationDetailJsonType) => {
  return {
    "@context": "https://schema.org/",
    "@type": "Restaurant",
    name: locationDetailJsonData.storeName,
    brand: locationDetailJsonData.storeName,
    address: {
      "@type": "PostalAddress",
      streetAddress: locationDetailJsonData.address,
      addressLocality: locationDetailJsonData.city,
      addressRegion: locationDetailJsonData.state,
      postalCode: locationDetailJsonData.zip,
      addressCountry: "US",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: locationDetailJsonData.latitude,
      longitude: locationDetailJsonData.longitude,
    },
    url: locationDetailJsonData.url,
    telephone: locationDetailJsonData.phone,
    servesCuisine: ["Fast Food", "Chicken Wings", "American"],
    priceRange: "$",
    image: LOGO_MAIN.src,
    menu: HOST + "/menu",
    openingHours: "Mo,Tu,We,Th,Fr,Sa,Su 10:30-23:00",
  };
};

export const faqDetailJson = (faqDetailJsonList: Array<Object>) => {
  const faqDetailJsonData = faqDetailJsonList.map((item: FaqDataType) => {
    return {
      "@type": "Question",
      name: item.questions,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    };
  });
  return {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: faqDetailJsonData,
  };
};

export const homeDetailJson = () => {
  return {
    "@context": "https://schema.org/",
    "@type": "Corporation",
    name: "Zaxbys",
    legalName: "Zaxbys SPE Franchisor LLC",
    logo: LOGO_MAIN.src,
    url: HOST,
    address: {
      "@type": "PostalAddress",
      streetAddress: "1040 Founders Blvd",
      addressLocality: "Athens",
      addressRegion: "GA",
      postalCode: "30606",
      addressCountry: "USA",
    },
  };
};
export const menuDetailJson = (url: string, menuUrl: string, productName: string, description: string, image: string, calories: string) => {
  return {
    "@context": "https://schema.org/",
    "@type": "Menu",
    name: "Zaxbys Menu",
    url: url,
    mainEntityOfPage: url,
    inLanguage: "English",
    hasMenuSection: [
      {
        "@type": "MenuSection",
        name: "Category name",
        url: menuUrl,
        hasMenuItem: {
          "@type": "MenuItem",
          name: productName,
          description: description,
          url: menuUrl,
          image: image,
          nutrition: {
            "@type": "NutritionInformation",
            calories: calories,
          },
        },
      },
    ],
  };
};
