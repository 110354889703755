import { ParsedUrlQuery } from "querystring";
import dynamic from "next/dynamic";
import { GetServerSidePropsContext } from "next";
import PageWrapper from "components/global/PageWrapper/PageWrapper";
import { homeDetailJson } from "utils/helper/jsonLd";
import Head from "components/global/Head/Head";
import { getPageProps, SEOData } from "utils/cms/pageHelper";
interface Props {
  finalSEO?: SEOData;
}

const HomePage = dynamic(() => import("containers/HomePage/HomePage"), { ssr: false });
const defaultSEO = {
  title: "Absolutely Craveable Chicken, Zalads & Zappetizers | Zaxbys",
  description: "Absolutely craveable, daringly zesty, made-to-order chicken fingers, wings and more. This is gonna be good.",
};

export const getServerSideProps = async (context: GetServerSidePropsContext<ParsedUrlQuery>) => {
  return getPageProps(context, defaultSEO);
};

const Index = ({ finalSEO }: Props) => {
  const jsonLd = homeDetailJson();
  const finalMetaData = { ...finalSEO, jsonLd };

  return (
    <>
      <PageWrapper>
        <Head {...finalMetaData} />
        <HomePage />
      </PageWrapper>
    </>
  );
};

export default Index;
